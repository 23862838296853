import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from "reactstrap";
import useAxiosAuth from "../../lib/useAxiosAuth";
import moment from 'moment';
import DefaultUserImg from "../../assets/images/users/default-user.jpg"

const PaymentUserList = () => {
  const axiosAuth = useAxiosAuth();
  const [tableList, setTableList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState(null);

  const date = new Date();

  // get Payment users api
  async function getPaymentUsers() {
    try {
      const res = await axiosAuth.get("admin_app/userManagement/");
      // console.log(res.data.results);
      setTableList(res.data.results);

      // Calculate total pages
      const totalCount = res.data.count;
      const pages = Math.ceil(totalCount / 10);
      setTotalPages(pages);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPaymentUsers();
  }, []);

  // Pagination handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Filter handler
  const handleFilter = (range) => {
    setFilter(range);
  };

  // Calculate start and end index for pagination
  const startIndex = (currentPage - 1) * 10;
  const endIndex = currentPage * 10;

  // Apply filter if set
  const filteredList = filter
    ? tableList.filter(table => table.created_on >= filter.start && table.created_on <= filter.end)
    : tableList;

  return (
    <div className="page-content">
      <Container fluid={true}>
        {/* Filter buttons */}
        {/* <div className="mb-3">
          <Button color="primary" onClick={() => handleFilter({ start: '2024-01-01', end: '2024-01-15' })}>Filter Jan 1-15</Button>{' '}
          <Button color="primary" onClick={() => handleFilter({ start: '2024-01-16', end: '2024-01-31' })}>Filter Jan 16-31</Button>{' '}
          <Button color="primary" onClick={() => handleFilter(null)}>Clear Filter</Button>
        </div> */}

        <Row>
          <Col md={12}>
            <Table hover size="lg" className="align-middle table-centered table-nowrap " responsive>
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th>Name Of User</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Transaction ID</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredList &&
                  filteredList.slice(startIndex, endIndex).map((table, i) => (
                    <tr key={table?.id}>
                      {/* <th scope="row">{startIndex + i + 1}</th> */}
                      <td>

                        <div className="d-flex align-items-center gap-4 fw-bold">
                          <span className="user-img">
                            <img className="img-fluid rounded rounded-circle"
                              src={DefaultUserImg}
                              alt="Default User Icon"
                              width="30"
                              height="30"
                            />
                          </span>
                          <span className="username">
                            {(table?.created_by?.first_name || "---") +
                              " " +
                              (table?.created_by?.last_name || "")}
                          </span>
                        </div>


                        {/* {table?.created_by.first_name} */}
                      </td>
                      <td>{moment(table.created_on).format('DD MMMM, YY')}</td>
                      <td>{moment(table.created_on).format("hh:mm a")}</td>
                      <td>{table?.payment?.order_id || "No Transaction"}</td>
                      <td><span> {table?.payment !== null ? <>&#8377; {table?.payment?.amount} </> : '-'}</span></td>
                      <td>
                        {table?.payment_status === 'complete' ?
                          <span className="text-success">Completed</span>
                          :
                          <span className="text-warning">Pending</span>
                        }

                        {/* {table?.payment_status} */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* Pagination */}
            <nav aria-label="Page navigation">
              <ul className="pagination pagination-rounded">
                {Array.from({ length: 5 }, (_, i) => i + 1).map(page => (
                  <li className={`page-item ${currentPage === page && 'active'}`} key={page}>
                    <button className="page-link" onClick={() => handlePageChange(page)}>
                      {page}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default PaymentUserList;