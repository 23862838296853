import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import CustomModal from "../../../Pages/Admin/Modal";
import { logoutUserSuccess, makeUserLogout } from "../../../store/actions";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [modal, setModal] = useState(false)

  const [username, setusername] = useState("Admin");
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  // console.log(localStorage.getItem("token"));

  return (
    <React.Fragment>
      {localStorage.getItem("token") ? (
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item "
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ms-2 me-2">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/main">
              <i className="ri-user-line align-middle me-2" />
              {props.t("My Account")}
            </DropdownItem>
            <Link to="/about">
              <DropdownItem tag="a" href="#">
                <i className="ri-wallet-2-line align-middle me-2" />
                {props.t("About Us")}
              </DropdownItem>
            </Link>
            <Link to="/contact-us">
              <DropdownItem tag="a">
                {/* <span className="badge bg-success float-end mt-1">11</span> */}
                <i className="ri-settings-2-line align-middle me-2" />
                {props.t("Contact Us")}
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </Dropdown>
      ) : (
        null
      )}

      

    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
