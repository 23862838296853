import axios from "axios";

const BASE_URL = process.env.BASE_URL;

const axiosInstance = axios.create({
    baseURL: "https://backend.astrolaxmi.in/",
    headers: { "Content-Type": "application/json" },
});

export const axiosAuth = axios.create({
    baseURL: "https://backend.astrolaxmi.in/",
    headers: { "Content-Type": "application/json" },
});

export default axiosInstance;
