const SidebarData = [
    // {
    //     label: "Menu",
    //     isMainMenu: true,
    // },
    {
        label: "Dashboard",
        icon: "bar-graph-3",
        url: "/dashboard",
        isHasArrow: true,
    },
    {
        label: "User Management",
        icon: "user-1",
        subItem: [
            { sublabel: "Pandit", subItemaIcon:"user-1", link: "/pandit" },
        ],
    },
    {
        label: "Courses",
        icon: "book-open",
        subItem: [
            { sublabel: "Modules", subItemaIcon:"book-closed", link: "/modules" },
        ],
    },
    {
        label: "Payments",
        icon: "wallet-2",
        subItem: [
            { sublabel: "Payment User List", subItemaIcon:"user-1", link: "/payment-user-list" },
            { sublabel: "Coupon & GST", subItemaIcon:"user-1", link: "/coupon-gst" },
        ],
    },
    {
        label: "CMS",
        icon: "wallet-2",
        subItem: [
            { sublabel: "Privacy Policy", subItemaIcon:"user-1", link: "/privacy-policy" },
            { sublabel: "Terms & Condition", subItemaIcon:"user-1", link: "/terms-condition" },
            { sublabel: "Introductory", subItemaIcon:"user-1", link: "/introductory" },
        ],
    },
    // {
    //     label: "Log Out",
    //     icon: "logout-1",
    //     url: "/login",
    // },
]
export default SidebarData;