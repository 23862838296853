import React, { useEffect, useState } from "react";
import LineColumnArea from "./LineColumnArea";
import { Card, CardBody, Col, Row } from "reactstrap";
import { OverViewData } from "../../CommonData/Data/index";
import useAxiosAuth from "../../lib/useAxiosAuth";
import SplineChart from "./SplineChart";

const OverView = () => {
  const axiosAuth = useAxiosAuth();
  const [category, setCategory] = useState("monthly");
  // const [monthlyData, setMonthlyData] = useState([]);
  // const [yearlyData, setYearlyData] = useState([]);

  const [data, setData] = useState([]);

  async function fetchGraphData() {
    try {
      const res = await axiosAuth.get("admin_app/revenueByLearnerGraph/");

      if (category === "monthly") {
        setData(res.data.monthly);
      }
      else {
        setData(res.data.yearly);
      }
      // console.log(res.data)


    } catch (error) {
      console.log(error);
    }
  }

  // fetching graph data
  useEffect(() => {
    fetchGraphData();
  }, [category]);

  // useEffect(() => {
  //   if (category==='monthly')
  // }, [category]);




  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="card-bordered">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title font-size-24">Revenue By Learner</h5>
              </div>

              <div className="flex-shrink-0 d-flex align-items-center">
                <div>
                  <p className="mr-4 mb-0 ">
                    <i style={{ color: '#97020C' }}
                      className={
                        "mdi mdi-circle font-size-12 me-1"
                      }
                    ></i>
                    Online
                  </p>

                </div>
                <div>
                  <select className="form-control" onChange={(e) => setCategory(e.target.value)}>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
            </div>

            <div>
              <SplineChart graphData={data} category={category} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default OverView;
