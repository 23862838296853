import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const Breadcrumbs = (props) => {
  return (
    <>
      <div className="page-title-box d-sm-flex align-items-center gap-2 justify-content-between">
        <h2 className="mb-0 font-size-24 ">{props.breadcrumbItem}</h2>
        <div className="page-title-right">
          <Breadcrumb listClassName="m-0">
            <BreadcrumbItem>
              <Link to={props.link}>{props.title}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {/* <Link to="#"> */}
              {props.breadcrumbItem}
              {/* </Link> */}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div></>
  );
}



export default Breadcrumbs;
