import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import useAxiosAuth from "../../lib/useAxiosAuth";
import { Modal } from "react-bootstrap";
import CustomIconButton from "../../components/Common/Button/IconButton";

const Modules = () => {
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [moduleList, setModuleList] = useState([]);
  const [moduleName, setModuleName] = useState("");
  const [videoFile, setVideoFile] = useState({});
  const [show, setShow] = useState(false);
  const [updateName, setUpdateName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Add Module API
  async function addModuleApi() {
    if (moduleName)
      try {
        const res = await axiosAuth.post("/operation/title/", {
          name: moduleName,
        });

        if (res) {
          getModuleApi();
          setModuleName();
        }
      } catch (error) {
        console.log(error);
      }
  }

  // Upload Video Link API
  const uploadVideoFn = async (assessment) => {
    var formdata = new FormData();
    formdata.append("assessment", assessment);
    formdata.append("link", videoFile.link.name);
    formdata.append("link_m", videoFile.link.name);
    formdata.append("link_l", videoFile.link.name);
    // formdata.append("duration", "2");

    try {
      const res = await axiosAuth.post(`/operation/videos/`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // get module api
  async function getModuleApi() {
    try {
      const res = await axiosAuth.get("/operation/title/");
      setModuleList(res?.data);
    } catch (error) {
      console.log(error);
    }
  }

  // update module api
  async function updateModuleApi(item) {
    // if (updateModule && id)
    try {
      const res = await axiosAuth.put(`/operation/title/${item.id}/`, {
        name: updateName,
      });
      handleClose();

      if (res) {
        getModuleApi();
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getModuleApi();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col md={12}>

              <div className="d-flex align-items-center gap-3 mb-5">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Type Module Name"
                  value={moduleName || ""}
                  required
                  onChange={(e) => setModuleName(e.target.value)}
                />

                <button className="btn btn-primary btn-with-icon btn-lg"
                  onClick={() => {
                    addModuleApi();
                  }}
                >
                  <span className="btn-icon"><i class="iconsax" icon-name="add"></i></span>
                  <span className="brn-text">add module</span>

                </button>
              </div>


            </Col>

          </Row>

          <Row>
            <Col md={12}>
              {moduleList && moduleList?.length > 0
                ? moduleList.map((item) => (
                  <div key={item?.id} className="card card-module">
                    <div className="d-flex align-items-center gap-4 mb-4">
                      <h4 className="mb-0">{item.name || "Modules 1"} </h4>
                      <button className="btn btn-grey btn-with-icon icon-after btn-sm"
                        onClick={() => {
                          setUpdateName(item.name);
                          handleShow();
                        }}
                      >
                        <i class="iconsax" icon-name="edit-2"></i>
                        <span className="btn-text">edit</span>
                      </button>
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={item.status === "active"} />
                      {item.status}
                    </div>
                    {/* {item?.name || ""} */}

                    {/* For updating the module name use this section*/}
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) =>
                            setVideoFile({
                              ...videoFile,
                              link: e.target.files[0],
                            })
                          }
                          placeholder="Upload Your Videos"
                        />

                        <div class="input-group-append">
                          <button onClick={() => uploadVideoFn(item.id)} className="btn btn-icon btn-absolute">
                            <i class="iconsax" icon-name="upload-square"></i>
                          </button>
                        </div>
                      </div>


                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) =>
                            setVideoFile({
                              ...videoFile,
                              link: e.target.files[0],
                            })
                          }
                          placeholder="Upload Your Videos"
                        />

                        <div class="input-group-append">
                          <button className="btn btn-icon" onClick={() => uploadVideoFn(item.id)}>
                            <i class="iconsax" icon-name="upload-square"></i>
                          </button>
                        </div>

                      </div>

                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) =>
                            setVideoFile({
                              ...videoFile,
                              link: e.target.files[0],
                            })
                          }
                          placeholder="Upload Your Videos"
                        />

                        <div class="input-group-append">
                          <button className="btn btn-icon" onClick={() => uploadVideoFn(item.id)}>
                            <i class="iconsax" icon-name="upload-square"></i>
                          </button>
                        </div>
                      </div>







                      {/* <button onClick={() => updateModuleApi({ id: item?.id })}>
                        Update
                      </button> */}
                    </div>
                    {/* end */}

                    {/* For adding quiz */}
                    <div className="quiz-button-bar">
                      <button className="btn text-primary btn-with-icon icon-after"
                        onClick={() => {
                          navigate(`/quiz/${item.id}`);
                        }}
                      >
                        <span className="btn-text">quiz section</span>
                        <span className="btn-icon"><i class="iconsax" icon-name="chevron-right"></i>
                        </span>
                      </button>
                    </div>
                    {/* end */}
                    <Modal show={show} onHide={handleClose} animation={true} centered>
                      <Modal.Header className="border-0">
                        <Modal.Title>Edit Module Name </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group">
                          <label for="EditModuleLabel" className="fw-regular">Enter Module Name</label>
                          <input
                            id="EditModuleLabel"
                            className="form-control"
                            type="text"
                            value={updateName}
                            onChange={(e) => setUpdateName(e.target.value)}
                          // placeholder=""
                          />
                        </div>

                      </Modal.Body>
                      <Modal.Footer className="border-0">
                        <button className="btn btn-grey" onClick={handleClose}>Cancel</button>
                        <button className="btn btn-primary"
                          onClick={() => {
                            updateModuleApi(item);
                          }}
                        >
                          Update Now
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                ))
                : null}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Modules;