import React from 'react'
import ReactApexChart from 'react-apexcharts';

const SplineChart = ({ graphData, category }) => {

    const obj1 = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sept',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
    }

    const SplineChartData = {
        series: [{
            name: 'Total Revenue',
            color: "#97020C",
            data: graphData && graphData?.length > 0
                ? graphData.map((item) => item?.total_amount)
                : [],
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: "month",
                categories: graphData && graphData?.length > 0
                    ? graphData.map((item) => {
                        if (category === 'monthly') {
                            return obj1[item?.month] + ' ' + item?.year
                        }
                        else {
                            return item?.year
                        }
                    })
                    : []
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },

    };
    return (
        <div>
            <div id="chart">
                <ReactApexChart options={SplineChartData.options} series={SplineChartData.series.length > 0 ? SplineChartData.series : []} type="area" height={350} />
            </div>
            <div id="html-dist"></div>
        </div>
    )
}

export default SplineChart