import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import useAxiosAuth from "../../lib/useAxiosAuth";
import {Modal} from "react-bootstrap";

const Introductory = () => {
  const axiosAuth = useAxiosAuth();
  const [editId,setEditId] = useState(null);
    const [formData, setFormData] = useState({
      intro:'',
      benefit:'',
      learn:'',
    });
    const [show, setShow] = useState(false);
    const [intro, setIntro] = useState("");
   

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

      // get module api
  async function getPrivacy() {
    try {
      const res = await axiosAuth.get(`operation/homePage/`);
      console.log(res.data[0]);
       setIntro(res.data[0])
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e) =>{
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleSubmit = async(e) =>{
    e.preventDefault();
    try {
      const res = await axiosAuth.put(`operation/homePage/${editId}/`,{...formData});
      console.log(res);
      handleClose();
      getPrivacy();
    } catch (error) {
      console.log(error);
    }

  }
  useEffect(() => {
    console.log("getting quiz home");
    getPrivacy();
  }, []);
  return (
    <div className="page-content">
    <Container fluid={true}>
      <div style={{display:'flex',justifyContent:'space-between'}}>
      <h1>Description</h1>
      <button
                        onClick={() => {
                          // setUpdateName(item.name)
                          handleShow();
                          // updateModuleApi(item.id);
                        }}
                      >
                        edit
                      </button>
      </div>
      <div>
        <p>{intro?.intro}</p>
      </div>
      <div>
        <h1>Benefits</h1>
        <p>{intro?.benefit}</p>
      </div>
      <div>
        <h1>what you'll learn</h1>
        <p>{intro?.learn}</p>
      </div>
      <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Module Name </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
            <Modal.Body>
            {/* <textarea type = "text" value = {intro.intro} onChange={(e)=>setIntro(e.target.value)} rows={50} style={{width:'100%'}} /> */}
            <div style={{display:'flex',flexDirection:'column'}}>
            <label>Description</label>
           <textarea name = "intro" value = {formData?.intro ? formData?.intro : intro?.intro} onChange={handleChange} rows={10}>{intro?.intro}</textarea>
            <label>Benefits</label>
            <textarea name = "benefit" value = {formData?.benefit ? formData?.benefit : intro?.benefit} onChange={handleChange} rows={10}>{intro?.benefit}</textarea>
            <label>what you'll learn</label>
            <textarea name = "learn" value = {formData.learn ? formData.learn : intro?.learn} onChange={handleChange} rows={10}>{intro?.learn}</textarea>
            </div>
            </Modal.Body>
            <Modal.Footer>
              <button  type="submit" onClick={() => {
                          setEditId(intro.id)
                         
                        }}>Update Now</button>
            </Modal.Footer>
            </form>
          </Modal>
    </Container>

    </div>
  )
}

export default Introductory