import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAxiosAuth from "../../lib/useAxiosAuth";
import { Col, Container, Row } from 'reactstrap';
import UserImg from '../../assets/images/users/avatar-1.jpg'

const PanditDetails = () => {
  const [panditDetails, setPanditDetails] = useState({});
  const { id } = useParams();
  const axiosAuth = useAxiosAuth();


  const fetchPandintTableDataFn = async () => {
    try {
      const res = await axiosAuth.get(`admin_app/userManagement/${id}`);
      console.log(res);
      setPanditDetails(res?.data)
    } catch (error) {
      console.log(error);
    }
  };

  // fetching pandit data
  useEffect(() => {
    fetchPandintTableDataFn();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>

          <Row>
            <Col md={12}>
              <div className="card card-pandit">
                <div className="card-pandit-img">
                  <picture className='d-block'>
                    <img src={UserImg} width="150" height="150" loading='lazy' className='img-fluid' alt="" />
                  </picture>
                </div>

                <div className="card-pandit-content">
                  <div className="card-pandit-content-top">
                    <div className="card-pandit-content-top-left">
                      <ul className='list-style-none'>
                        <li>
                          <span className='fw-bold'>Name:</span>
                          <span className='fw-regular'>Manoj Sharma</span>
                        </li>
                        <li>
                          <span className='fw-bold'>Email:</span>
                          <span className='fw-regular'>manoj005@gmail.com</span>
                        </li>
                      </ul>
                    </div>
                    <div className="card-pandit-content-top-right">
                      <button className='btn btn-danger btn-lg'><span className="d-md-block d-none">Delete Pandit</span> <span className="d-md-none d-block"><i className="iconsax" icon-name="trash"></i></span></button>
                    </div>
                  </div>

                  <hr className='my-4' />

                  <div className="card-pandit-content-bottom">
                    <ul>
                      <li>
                        <span className='fw-bold'>Date of Join:</span>
                        <span className='fw-grular'>26 August, 2023</span>
                      </li>
                      <li>
                        <span className='fw-bold'>Certification:</span>
                        <span className='fw-grular'>No Certified</span>
                      </li>
                      <li>
                        <span className='fw-bold'>Course Progress:</span>
                        <span className='fw-grular'>60% (Progress)</span>
                      </li>
                      <li>
                        <span className='fw-bold'>Status:</span>
                        <span className='fw-grular'>Active</span>
                      </li>
                      <li>
                        <span className='fw-bold'>Gender:</span>
                        <span className='fw-grular'>Male</span>
                      </li>
                      <li>
                        <span className='fw-bold'>D.O.B:</span>
                        <span className='fw-grular'>15 Dec, 1976</span>
                      </li>
                      <li>
                        <span className='fw-bold'>Address:</span>
                        <span className='fw-grular'>A-52 Anand Vehar Society
                          New Delhi</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    // <div className='page-content'>
    //   <div style={{ display: 'flex', flexDirection: 'column' }}>
    //     <div>
    //       <img src="" alt="" />
    //     </div >
    //     <div style={{ display: 'flex', flexDirection: 'row' }}>
    //       <div>
    //         <div><label>Name:</label> <span>Sra</span></div>
    //         <div><label>Email:</label> <span>Sra</span></div>
    //         <div><label>Date of Join:</label> <span>Sra</span></div>
    //         <div><label>Course Progress:</label> <span>Sra</span></div>
    //         <div><label>Gender:</label> <span>Sra</span></div>
    //         <div><label>Address:</label> <span>Sra</span></div>
    //       </div>
    //       <div>
    //         <div><label>Certification:</label> <span>Sra</span></div>
    //         <div><label>Status:</label> <span>Sra</span></div>
    //         <div><label>D.O.B :</label> <span>Sra</span></div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default PanditDetails