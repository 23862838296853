import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function CustomModal({modal, setModal}) {

  const toggle = () => setModal(!modal);

  const handleDelete = (e) =>{
    e.prventDefault();
   localStorage.removeItem('token');
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
        <form onSubmit = {handleDelete}>
        <ModalBody>
         are you sure you want to delete
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle} type='submit'>
            confirm
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default CustomModal;