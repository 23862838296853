import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import useAxiosAuth from "../../lib/useAxiosAuth";
import DefaultUserImg from "../../assets/images/users/default-user.jpg";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";
//import Table from 'react-bootstrap/Table';

const PanditManagement = () => {
  // document.title = "Astro Laxmi | User Management";
  const axiosAuth = useAxiosAuth();
  const [PanditDataTable, setPanditDataTable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState({
    prev: null,
    next: null,
  });
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");

  const fetchPandintTableDataFn = async () => {
    setIsLoading(true);
    try {
      const res = await axiosAuth.get(
        `admin_app/userManagement/?page=${page}`
      );
      if (res) {
        setPanditDataTable(res?.data?.results);
        //setTotalCount(res?.data?.count);
        setDisable({
          prev: res?.data?.previous,
          next: res?.data?.next,
        });
      }
      setIsLoading(false);
      console.log(res.data.data.next);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // fetching pandit data
  useEffect(() => {


    fetchPandintTableDataFn();
  }, [page]);

  const filterSearch = PanditDataTable?.filter((pandit) => {
    const Names = pandit.created_by?.first_name.toLowerCase();
    return Names.includes(search.toLowerCase());
  });

  useEffect(() => {
    if (search) {
      setPanditDataTable(filterSearch);
    } else {
      fetchPandintTableDataFn()
    }
  }, [search]);

  // let totalPages = Math.ceil(totalCount / 50);
  // let mid = Math.floor(totalPages / 2)
  // console.log(mid)

  let active = 2;
  let items = [];
  for (let number = 1; number <= page.length; number++) {
    items.push(
      <Pagination.Item key={page.number} active={page.number === active}>
        {page.number}
      </Pagination.Item>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>

          <Row>
            <Col md={12}>
              <Card className="card-bordered gap-4">
                <Row>

                  <Col md={9}>
                    <form className="form-inline">
                      <label htmlFor="SearchUser" className="mr-3">Search:</label>
                      <input
                        type="text"
                        placeholder="search"
                        className="form-control"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                    </form>
                  </Col>

                  <Col md={3}>
                    <Link to="add-pandit" className="btn btn-primary btn-with-icon w-auto">
                      <span className="btn-icon"><i className="iconsax" icon-name="add"></i></span>
                      <span className="btn-text">Add Pandit</span>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    {isLoading ? (
                      "Loading"
                    ) : (
                      <Table
                        hover
                        size="lg"
                        className="align-middle table-centered table-nowrap "
                        responsive
                      >
                        <thead>
                          <tr>
                            <th className="">Name</th>
                            <th>Date of joined</th>
                            <th>Course Progress</th>
                            <th>Email</th>
                            <th>Status</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {PanditDataTable && PanditDataTable?.length > 0
                            ? PanditDataTable.map((row) => {
                              return (
                                <tr key={row.id}>
                                  <td>
                                    <div className="d-flex align-items-center gap-4 fw-bold">
                                      <span className="user-img">
                                        <img
                                          className="img-fluid rounded rounded-circle"
                                          src={DefaultUserImg}
                                          alt="Default User Icon"
                                          width="30"
                                          height="30"
                                        />
                                      </span>
                                      <span className="username">
                                        <Link to={`/pandit/${row.id}`}>
                                          {(row?.created_by?.first_name || "---") +
                                            " " +
                                            (row?.created_by?.last_name || "")}
                                        </Link>
                                      </span>
                                    </div>{" "}
                                  </td>
                                  <td>
                                    {row?.created_on === null
                                      ? ""
                                      : moment(row?.created_on).format(
                                        "MMMM DD, YYYY"
                                      )}
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="d-flex gap-2 align-items-center">
                                      <span className="circular-progress-primary progress-sm">
                                        <CircularProgressbar
                                          value={row?.course_status}
                                          text={`${row?.course_status}%`}
                                        />
                                      </span>{" "}
                                      <span className="text">Completed</span>
                                    </div>
                                    {/* {row?.course_status + "% completed" || "---"} */}
                                  </td>
                                  <td>{row?.email}</td>
                                  <td>
                                    {row?.payment_status === "complete" ? (
                                      <span className="text-success">Active</span>
                                    ) : (
                                      <span className="text-danger">Inactive</span>
                                    )}
                                  </td>
                                  {/* <td>
                  <button>Remove</button>
                </td> */}
                                </tr>
                              );
                            })
                            : null}
                        </tbody>
                      </Table>
                    )}

                    <Pagination className="pagination-rounded">
                      <Pagination.Prev
                        onClick={() => setPage((prev) => prev - 1)}
                        disabled={disable.prev === null}
                        className="prev"
                      />
                      {page.length > 1 ? (
                        <Pagination.Item
                          onClick={() => setPage((prev) => page - 1)}
                          disabled={disable.prev === null}
                        >
                          {page - 1}
                        </Pagination.Item>
                      ) : (
                        ""
                      )}

                      <Pagination.Item active>{page}</Pagination.Item>

                      <Pagination.Item
                        onClick={() => setPage((prev) => page + 1)}
                        disabled={disable.prev === null}
                      >
                        {page + 1}
                      </Pagination.Item>

                      <Pagination.Ellipsis />

                      <Pagination.Next
                        className="next"
                        onClick={() => setPage((prev) => prev + 1)}
                        disabled={disable.next === null}
                      />
                    </Pagination>

                    {/* <Pagination>{items}</Pagination> */}

                    {/* <Pagination>
    <Pagination.First onClick={() => setPage(1)} />
    <Pagination.Prev onClick={() => setPage((prev) => prev - 1)} />
    <Pagination.Ellipsis />

    <Pagination.Item>{mid}</Pagination.Item>
    <Pagination.Item>{mid + 1}</Pagination.Item>
    <Pagination.Item active>{mid + 2}</Pagination.Item>
    <Pagination.Item>{mid + 3}</Pagination.Item>
    <Pagination.Ellipsis />
    <Pagination.Next />
    <Pagination.Last />
  </Pagination> */}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default PanditManagement;
