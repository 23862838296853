import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import CustomModal from './Modal';


const Main = () => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const token = localStorage.getItem('token');
    console.log(token);
    return (
    <div className='page-content'>
      
     
              <button onClick={()=>navigate('/login')}>Login</button>
      
             {/* <button onClick={()=>setModal(true)}>logout</button>
       
      

       

<CustomModal modal={modal} setModal={setModal} /> */}
    </div>
  )
}

export default Main