import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Modal } from "react-bootstrap";
import useAxiosAuth from "../../lib/useAxiosAuth";
import moment from "moment";
import CouponImg from "../../assets/images/svg/coupon/coupon.svg"
import DummyCouponIcon from "../../assets/images/svg/coupon/dummy-coupon-icon.svg"

const Coupon = () => {
  const axiosAuth = useAxiosAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [getCoupons, setGetCoupons] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const [editId, setEditId] = useState(null);

  const [formValues, setFormValues] = useState({
    code: "",
    discountType: "",
    discount: "",
    description: "",
    created_on: "",
    gst: "18",
  });

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // get coupon api pay/coupon/
  async function postCouponApi() {
    try {
      const res = await axiosAuth.post(
        "pay/coupon/",
        {
          // code: formValues.code,
          code: '',
          discountType: formValues.discountType,
          created_on: formValues.created_on,
          discount: formValues.discount,
          description: formValues.description,
          gst:formValues.gst
        }
      );
      console.log(res.data);
      getCouponApi();
    } catch (error) {
      console.log(error);
    }
  }

  // get module api
  async function getCouponApi() {
    try {
      const res = await axiosAuth.get("pay/coupon/");
      console.log(res.data);
      setGetCoupons(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCouponApi();
  }, []);

  const handleDelete = async (id) => {
    console.log(id, "id");
    try {
      const res = await axiosAuth.delete(`pay/coupon/${id}/`);
      console.log(res.data);
      getCouponApi();
    } catch (error) {
      console.log(error);
    }
  };
  // const handleEdit = async (item) => {
  //   setEditable(true);
  //   handleShow();
  //   try {
  //     const res = await axiosAuth.put(`pay/coupon/${item.id}/`, {
  //       code: formValues.code,
  //     });
  //     getCouponApi();
  //     console.log(res.data);

  //     // getCouponApi();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosAuth.put(`pay/coupon/${editId}/`, {
        ...formValues,
      });
      handleClose();
      getCouponApi();
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div className="btn-container text-right mb-5">
                <button className="btn btn-primary"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  Create Coupon
                </button>
              </div>


              <div className="coupons-list-container">
                {getCoupons
                  ? getCoupons.map((item, index) => (
                    <>
                      <div className="card card-coupon mb-4" key={index}>
                        <div className="card-icon">
                          <img src={DummyCouponIcon} height="60" width="60" className="img-fluid" alt="Dummy Coupon Brand Logo" />
                        </div>

                        <div className="card-coupon-content">
                          <div className="card-left">
                            <h3 className="card-coupon-dicount-type">{item?.discount} {item?.discountType === "percentage" ? '%' : 'Flat'}</h3>
                            <div className="card-coupon-discount-brand">{item?.code}</div>
                            <small className="card-coupon-validity text-muted">{item?.description}</small>
                          </div>
                          <div className="card-right">
                            <ul className="card-coupon-actions">
                              <li className="card-coupon-actions-delete">
                                <button className="btn btn-lg" onClick={() => handleDelete(item.id)}>
                                  <i className="iconsax" icon-name="trash"></i>
                                </button>
                              </li>
                              <li className="card-coupon-actions-edit">
                                <button className="btn btn-lg pr"
                                  onClick={() => {
                                    handleShow();
                                    setEditable(true);
                                    setEditId(item.id);
                                    console.log("item.created_on", item.created_on);
                                    setFormValues({
                                      code: item.code,
                                      discountType: item.discountType,
                                      discount: item.discount,
                                      description: item.description,
                                      created_on: moment(item.created_on).format(
                                        "YYYY-MM-DD"
                                      ),
                                    });
                                  }}
                                >
                                  <i className="iconsax" icon-name="edit-2"></i>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>

                      <Modal show={show} onHide={handleClose} animation={true} centered>
                        <Modal.Header className="border-0">
                          <Modal.Title>
                            {isEditable ? "Edit" : "Create"} Module Name{" "}
                          </Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleSubmit}>
                          <Modal.Body>

                            <div className="form-group mb-4">
                              <label>Coupon Name</label>
                              <input
                                className="form-control"
                                type="text"
                                name="code"
                                value={formValues.code}
                                required
                                onChange={handleChange}
                              />
                            </div>

                            <div className="form-group mb-4">
                              <label>Coupon Category</label>
                              <select
                                name="discountType"
                                value={formValues.discountType}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option selected disabled>
                                  Select Category
                                </option>
                                <option value="flat">Flat Discount</option>
                                <option value="percentage">
                                  Percentage Discount
                                </option>
                              </select>
                            </div>

                            <div className="form-group mb-4">
                              <label>Coupon Discount</label>
                              <input
                                className="form-control"
                                type="text"
                                name="discount"
                                value={formValues.discount}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="form-group mb-4">
                              <label>Valid Date</label>
                              <input
                                type="date"
                                name="created_on"
                                value={formValues.created_on}
                                onChange={handleChange}
                              />
                            </div>
                            
                            <div className="form-group mb-4">
                              <h4 className="title">GST</h4>
                            </div>

                            <div className="form-group mb-4">
                              <label>GST Percentage</label>
                              <input
                              className="form-control"
                                type="text"
                                name="gst"
                                value={formValues.gst}
                                onChange={handleChange}
                              />
                            </div>
                            
                           
                          </Modal.Body>
                          <Modal.Footer className="border-0 justify-content-start">
                            {/* <button  onClick={() => {
                          updateModuleApi(item);
                         
                        }}>Update Now</button> */}
                            {isEditable ? (
                              <button className="btn btn-primary" type="submit">update</button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  postCouponApi();
                                  handleClose();
                                }}
                              >
                                save
                              </button>
                            )}

                            <button className="btn btn-grey" onClick={handleClose}>Close</button>
                          </Modal.Footer>
                        </form>
                      </Modal>
                    </>
                  ))
                  : <>

                    <Col md={12}>
                      <div className="card card-empty-quiz shadow-0 br-0 d-flex flex-column align-items-center justify-cintent-center gap-3 text-center ">

                        <div className="card-img">
                          <img src={CouponImg} width="200" height="200" className="img-fluid mx-auto" alt="coupon Icon" />
                        </div>
                        <h4 className="card-title fw-bold display-4">Create Coupon</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate omnis nobis obcaecati quibusdam labore reprehenderit doloribus possimus nihil porro esse quam repudiandae provident dolorem quasi et, incidunt aliquam amet odio!</p>
                        <button className="btn btn-primary"
                          onClick={() => {
                            handleShow();
                          }}
                        >
                          Create Coupon
                        </button>
                      </div>
                    </Col>

                  </>}
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Coupon;
