import React, { useState } from 'react';

const MyAccount = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    address: '',
    password: '',
    confirmPassword: '',
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleDeleteImage = () => {
    setFormData({
      ...formData,
      image: null,
    });
  };

  return (
    <div className='page-content'>
    <form>
    
    {formData.image && (
        <div>
          <img src={URL.createObjectURL(formData.image)} alt="preview" />
          <button type="button" onClick={handleDeleteImage}>
            Delete Image
          </button>
        </div>
      )}
    <label>
        Upload Image:
        <input type="file" onChange={handleImageChange} />
      </label>
      <label>
        Full Name:
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
        />
      </label>
      <label>
        Email Address:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </label>
      <label>
        Phone Number:
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </label>
      <label>
        Address:
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
      </label>
      <label>
        Password:
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
      </label>
      <label>
        Confirm Password:
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
        />
      </label>

      {/* <button type="submit">Submit</button> */}
    </form>
    </div>
  );
};

export default MyAccount;
