import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Col, Container, Row } from "reactstrap";

import useAxiosAuth from "../../lib/useAxiosAuth";
import { Modal } from "react-bootstrap";

const Terms = () => {

  const [show, setShow] = useState(false);
  const [tcDetails, setTCDetails] = useState({
    heading: "",
    content: "",
  });

  const axiosAuth = useAxiosAuth();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get module api
  async function getTerms() {
    try {
      const res = await axiosAuth.get(`operation/termsandcondition/`);
      setTCDetails(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getTerms();
  }, [show]);



  const handleSubmit = async (e) => {
    e.preventDefault()
    let formdata = new FormData();
    formdata.append("heading", tcDetails.heading);
    formdata.append("content", tcDetails.content);
    try {
      const res = await axiosAuth.put(`operation/termsandcondition/${tcDetails.id}/`, formdata);
      handleClose();
    } catch (e) {
      console.log(e);
    }

  };

  console.log(tcDetails)

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Row>
          <Col md={12}>
            <div className="page-header d-flex align-itens-center justify-content-between mb-5">
              <h1 id="heading">Terms and Conditions</h1>
              <button className="btn btn-grey btn-with-icon icon-after"
                onClick={() => {
                  handleShow();
                }}
              >
                <span className="btn-icon"><i className="iconsax" icon-name="edit-2"></i></span>
                <span className="btn-text">edit</span>
              </button>
            </div>
            <h1>{tcDetails.heading}</h1>
            <p>{tcDetails.content}</p>
          </Col>
        </Row>


        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Module Name </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-4">
                <input
                  type="text"
                  name="heading"
                  className="form-control"
                  value={tcDetails.heading}
                  onChange={(e) => setTCDetails({ ...tcDetails, heading: e.target.value })}
                />
              </div>

              <div className="form-group mb-4">
                <textarea
                  type="text"
                  name="content"
                  className="form-control"
                  value={tcDetails.content}
                  onChange={(e) => setTCDetails({ ...tcDetails, content: e.target.value })}
                  rows={20}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary"
                type="submit"
              >
                Update Now
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </Container>
    </div>
  );
};

export default Terms;
