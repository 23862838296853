import React, { useEffect, useState } from "react";
import useAxiosAuth from "../../lib/useAxiosAuth";
import { Container, FormGroup } from "reactstrap";
import { Modal } from "react-bootstrap";

const About = () => {
  const axiosAuth = useAxiosAuth();
  const [editId, setEditId] = useState(null);
  const [show, setShow] = useState(false);
  const [aboutDetails, setAboutDetails] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getAboutDetail() {
    try {
      const res = await axiosAuth.get("operation/aboutUs/");
      console.log(res.data)
      setAboutDetails(res?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(aboutDetails);
    let formdataAPI = new FormData();
    formdataAPI.append("content", aboutDetails);
    try {
      const res = await axiosAuth.put(`operation/aboutUs/${editId}/`, formdataAPI);
      handleClose();
      //getAboutDetail();
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAboutDetail();
  }, []);

  console.log(aboutDetails);

  return (
    <div className="page-content">
      <Container fluid={true}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>About Us</h1>
          <button
            className="btn btn-grey btn-with-icon icon-after btn-sm"
            onClick={() => {
              handleShow();
            }}
          >
            <span className="btn-icon">
              <i className="iconsax" icon-name="edit-2"></i>
            </span>
            <span className="btn-text">edit</span>
          </button>
        </div>
        <div>
          <p>{aboutDetails?.content}</p>

        </div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Edit About Us Content </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <FormGroup className="mb-3">
                <label>About Us</label>
                <textarea
                  name="content"
                  className="form-control"
                  value={aboutDetails?.content}
                  onChange={(e) => setAboutDetails({ ...aboutDetails, content: e.target.value })}
                  rows={10}
                />
              </FormGroup>

              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    setEditId(aboutDetails.id);
                  }}
                >
                  Update Now
                </button>
              </div>
            </Modal.Body>
          </form>
        </Modal>
      </Container>
    </div>
  );
};

export default About;
