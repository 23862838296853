import React,{useState} from 'react'
import useAxiosAuth from '../../lib/useAxiosAuth';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate();
    const axiosAuth = useAxiosAuth();
    const [formData, setFormData] = useState({
        phone : "",
    });
    const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value });
    }
    const handleSubmit = async(e)=>{
        e.preventDefault();
        const formValues  = new FormData()
        formValues.append("phone",formData.phone)
        try {
          const res = await axiosAuth.post(`accounts/register/`,formValues,{
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(res);
          navigate('/login')
        } catch (error) {
           console.log(error);
        }
    }
  return (
    <div className='page-content'>
    <form onSubmit={handleSubmit}>
        <label>Enter Your Phone Number</label>
        <input type = "text" name = "phone" value = {formData.phone} onChange={handleChange}  style={{border:'1px solid grey'}}/>
        <button type = "submit">Register</button>
        </form>
    </div>
  )
}

export default Register