import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import useAxiosAuth from "../../lib/useAxiosAuth";
import NumberFormat from "../../helpers/NumberFormat";

const UserPanel = () => {
  const axiosAuth = useAxiosAuth();
  const [dataDashboardCount, setDataDashboardCount] = useState({});

  function formatNumberFn(num) {
    const options = { maximumFractionDigits: 2 };
    const formattedNumber = Intl.NumberFormat("en-US", options).format(num);
    return formattedNumber;
  }

  const fetchDashboardCardsFn = async () => {
    try {
      const res = await axiosAuth.get("admin_app/dashboard_counts/");
      setDataDashboardCount(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // fetching card data
  useEffect(() => {
    fetchDashboardCardsFn();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <div className="dashboard-counter-card">
            <Card className="rounded rounded-3 border-end shadow-0">
              <CardBody>
                <div className="card-icon d-inline-flex align-items-center justify-content-center mb-3 p-3">
                  <i
                    className="iconsax text-primary fs-4"
                    icon-name="user-1"
                  ></i>
                </div>

                <div className="content mt-0 ps-0">
                  <h3 className="mb-3 fs-1 fw-bold">
                    {formatNumberFn(
                      dataDashboardCount?.total_active_registered_users
                    ) || 0}
                  </h3>
                  <p className="text-muted mb-0 fs-5">
                    Total active registered users
                  </p>
                </div>
              </CardBody>
            </Card>

            <Card className="rounded rounded-3 border-end shadow-0">
              <CardBody>
                <div className="card-icon d-inline-flex align-items-center justify-content-center mb-3 p-3">
                  <i
                    className="iconsax text-primary fs-4"
                    icon-name="user-1"
                  ></i>
                </div>

                <div className="content mt-0 ps-0">
                  <h3 className="mb-3 fs-1 fw-bold">
                    {formatNumberFn(
                      dataDashboardCount?.total_revenue_by_learner_amount
                    ) || 0}
                  </h3>
                  <p className="text-muted mb-0 fs-5">
                    Total Revenue by Learner
                  </p>
                </div>
              </CardBody>
            </Card>

            <Card className="rounded rounded-3 border-end shadow-0">
              <CardBody>
                <div className="card-icon d-inline-flex align-items-center justify-content-center mb-3 p-3">
                  <i
                    className="iconsax text-primary fs-4"
                    icon-name="user-1"
                  ></i>
                </div>

                <div className="content mt-0 ps-0">
                  <h3 className="mb-3 fs-1 fw-bold">
                    {formatNumberFn(
                      dataDashboardCount?.total_user_with_complete_course
                    ) || 0}
                  </h3>
                  <p className="text-muted mb-0 fs-5">
                    Total User with Completed Course
                  </p>
                </div>
              </CardBody>
            </Card>

            <Card className="rounded rounded-3 border-end shadow-0">
              <CardBody>
                <div className="card-icon d-inline-flex align-items-center justify-content-center mb-3 p-3">
                  <i
                    className="iconsax text-primary fs-4"
                    icon-name="user-1"
                  ></i>
                </div>

                <div className="content mt-0 ps-0">
                  <h3 className="mb-3 fs-1 fw-bold">
                    {formatNumberFn(
                      dataDashboardCount?.total_active_lerner_count
                    ) || 0}
                  </h3>
                  <p className="text-muted mb-0 fs-5">Total Active Learner</p>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
