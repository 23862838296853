import React, { useEffect, useState } from 'react';
import useAxiosAuth from '../../lib/useAxiosAuth';
import { Modal } from "react-bootstrap";


const Contact = () => {
    const axiosAuth = useAxiosAuth();
    const [contact, setContact] = useState(null);
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState(null);
  
    const [formData, setFormData] = useState({
      email:"",
      phone:"",
      address:""
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlechange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(formData);
      try {
        const res = await axiosAuth.put(
          `operation/contactUs/${editId}/`,
          {
            ...formData,
          }
        );
        handleClose();
        getContactDetail();
        console.log(res.data);
      } catch (e) {
        console.log(e);
      }
  
    };
    
    async function getContactDetail() {
        try {
          const res = await axiosAuth.get("operation/contactUs/");
          console.log(res.data);
          setContact(res.data)
          document.getElementById('contact').innerHTML = res.data.address;
       
        } catch (error) {
          console.log(error);
        }
      }

      useEffect(() => {
        getContactDetail();
      }, []);
    
    
  return (
    <div className='page-content'>
         <div style={{display:'flex',justifyContent:'space-between'}}> 
        <h1>Contact Us</h1>
        <button onClick={()=>handleShow()}>Edit</button>
        </div>
        <div>
        <p>{contact?.email}</p>
        <p>{contact?.phone}</p>
        <p id = "contact"></p>
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Contact Us </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
            <Modal.Body>
            {/* <textarea type = "text" value = {intro.intro} onChange={(e)=>setIntro(e.target.value)} rows={50} style={{width:'100%'}} /> */}
            <div style={{display:'flex',flexDirection:'column'}}>
            <label>Phone</label>
            <input type = "text" name = "phone" value = {formData?.phone ? formData?.phone : contact?.phone} onChange={handlechange} />
            <label>Email</label>
            <input type = "email" name = "email" value = {formData?.email ? formData?.email : contact?.email} onChange={handlechange} />
            <label>address</label>
            <input type = "text" name = "address" value = {formData?.address ? formData?.address : contact?.address} onChange={handlechange} />
            </div>
            </Modal.Body>
            <Modal.Footer>
              <button  type="submit" onClick={() => {
                          setEditId(contact.id)
                           handleShow();
                        }}>Update Now</button>
            </Modal.Footer>
            </form>
          </Modal>
        </div>
    </div>
  )
}

export default Contact