import React, { useState } from 'react'
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import useAxiosAuth from '../../lib/useAxiosAuth'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const AddPandit = () => {

    const [formDetails, setFormDetails] = useState({
        phone: "",
        email: "",
        gender: "",
        dob: "",
        address: "",
        first_name: "",
        last_name: "",
        created_on: "",
        picturePath: "",
        pictureName: "",
    })

    const axiosAuth = useAxiosAuth()
    const navigate = useNavigate()

    const addPanditHandler = async (e) => {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("phone", formDetails.phone);
        formdata.append("email", formDetails.email);
        formdata.append("gender", formDetails.gender);
        formdata.append("dob", formDetails.dob);
        formdata.append("address", formDetails.address);
        formdata.append("first_name", formDetails.first_name);
        formdata.append("last_name", formDetails.last_name);
        formdata.append("created_on", formDetails.created_on);

        if (formDetails.picturePath !== "" || formDetails.pictureName !== "") {
            formdata.append("picture", formDetails.picturePath, formDetails.pictureName);
        }

        try {
            const res = await axiosAuth.post("accounts/registrationByAdmin/", formDetails)
            if (res.status === 200) {
                toast.success("Pandit Added Successfully")
                navigate("/pandit")
            }
        } catch (error) {
            console.log(error)
            if (error.code === "ERR_BAD_REQUEST") {
                toast.error(JSON.stringify(error.response.data.message))
            }
        }



    }

    const imageUploadHandler = (e) => {
        setFormDetails({ ...formDetails, pictureName: e.target.value, picturePath: e.target.files[0] })
    }
    console.log(formDetails)
    return (

        <div className="page-content">
            <Container fluid={true}>
                <Card className="card-addPandit card-bordered ">
                    <CardBody>
                        <Breadcrumbs title="Pandit" breadcrumbItem="Add Pandit" link='/pandit' />
                        <hr className='mb-3 mt-0' />
                    </CardBody>
                    <CardBody>
                        <form onSubmit={addPanditHandler}>

                            <Row>
                                <Col md={12} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">Upload Your Files Here</label>
                                        <input type="file" placeholder='Choose File' className='form-control' onChange={imageUploadHandler} />

                                    </FormGroup>

                                </Col>

                                <Col md={6} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">First Name</label>
                                        <input type="text" placeholder='Enter First Name' name='firstName' className='form-control' onChange={(e) => setFormDetails({ ...formDetails, first_name: e.target.value })} />
                                    </FormGroup>
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">Last Name</label>
                                        <input type="text" placeholder='Enter Forst Name' name='lastName' className='form-control' onChange={(e) => setFormDetails({ ...formDetails, last_name: e.target.value })} />
                                    </FormGroup>
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">Email Address</label>
                                        <input type="email" placeholder='Enter Email Address' className='form-control' onChange={(e) => setFormDetails({ ...formDetails, email: e.target.value })} />

                                    </FormGroup>
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">Phone Number</label>
                                        <input type="tel" placeholder='Enter Phone Number' className='form-control' onChange={(e) => setFormDetails({ ...formDetails, phone: e.target.value })} />

                                    </FormGroup>
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">Gender</label>
                                        <select name="grnder" id="gender" className='form-control' onChange={(e) => setFormDetails({ ...formDetails, gender: e.target.value })}>
                                            <option value="Select Gender" selected>Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>

                                    </FormGroup>
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">D.O.B</label>
                                        <input type="Date" placeholder='Choose Date' name='dob' className='form-control' onChange={(e) => setFormDetails({ ...formDetails, dob: e.target.value })} />

                                    </FormGroup>
                                </Col>
                                <Col md={12} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">Date of join</label>
                                        <input type="Date" placeholder='Date Of Join' name='doj' className='form-control' onChange={(e) => setFormDetails({ ...formDetails, created_on: e.target.value })} />
                                    </FormGroup>
                                </Col>
                                <Col md={12} className='mb-3'>
                                    <FormGroup>
                                        <label htmlFor="">Address</label>
                                        <textarea name="address" id="address" className='form-control' rows="5" placeholder='Enter Address' onChange={(e) => setFormDetails({ ...formDetails, address: e.target.value })}></textarea>
                                    </FormGroup>
                                </Col>

                                <Col md={12} className='mt-3'>
                                    <button className='btn btn-primary' type='submit'>Save Now</button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default AddPandit