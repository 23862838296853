import { useEffect } from "react";
import { axiosAuth } from "./axios";

const useAxiosAuth = () => {
  useEffect(() => {
    const requestIntercept = axiosAuth.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers[
            "Authorization"
          ] = `Token ${"c38ece007a6a2e7ee7eba1e69095e3a4a6e5c51c"}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axiosAuth.interceptors.request.eject(requestIntercept);
    };
  }, []);

  return axiosAuth;
};

export default useAxiosAuth;
