import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import useAxiosAuth from "../../lib/useAxiosAuth";
import AddUpdateQuiz from "./AddUpdateQuiz";
import EmptyQuizImg from "../../assets/images/svg/quiz/empty-quiz-img.svg"

const QuizHome = () => {
  const { assessment } = useParams();
  const axiosAuth = useAxiosAuth();
  const [openModal, setOpenModal] = useState(false);
  const [existingQuiz, setExistingQuiz] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState({});

  // Get Quiz API
  async function getAllQuizApi() {
    try {
      const res = await axiosAuth.get(`operation/module/?module=${assessment}`);
      if (res) setExistingQuiz(res?.data?.assessmentQuestion);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllQuizApi();
  }, [!openModal]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row style={{ marginTop: "2rem" }}>
            {existingQuiz.length > 1 ? "Existing quiz" : ""}
          </Row>
          <Row>

            {existingQuiz && existingQuiz?.length > 0 ? <><Col md={12} className="mb-4">
              <button className="btn btn-primary ml-auto"
                onClick={() => {
                  if (openModal) setOpenModal(false);
                  else setOpenModal("add");
                }}
              >
                Add Quiz
              </button>
            </Col>

            </> : null}

            {existingQuiz && existingQuiz?.length > 0
              ? existingQuiz.map((quiz) => (
                <div key={quiz?.id} className="card card-quiz-qa">
                  <div className="card-quiz-qa-top mb-3">
                    <h6 className="mb-0"><b>Qusetion.</b> {quiz?.question} ?</h6>
                    <button className="btn btn-with-icon icon-after btn-secondary btn-sm"
                      onClick={() => {
                        if (openModal) setOpenModal(false);
                        else {
                          setOpenModal("update");
                          setSelectedQuiz(quiz);
                        }
                      }}
                    >
                      {openModal ? <>
                        <span className="btn-icon"><i className="iconsax" icon-name="x-circle"></i></span>
                        <span className="btn-text">Close Quiz</span>

                      </> : <>
                        <span className="btn-icon">
                          <i className="iconsax" icon-name="edit-2"></i>
                        </span>
                        <span className="btn-text">Edit quiz</span>
                      </>}
                    </button>
                  </div>

                  <Row>
                    <Col md={6} className="mb-3">A) {quiz?.option_1}</Col>
                    <Col md={6} className="mb-3">B) {quiz?.option_2}</Col>
                    <Col md={6} className="mb-3">C) {quiz?.option_3}</Col>
                    <Col md={6} className="mb-3">D) {quiz?.option_4}</Col>
                  </Row>
                </div>
              ))
              : <>

                <Col md={12}>
                  <div className="card card-empty-quiz shadow-0 br-0 d-flex flex-column align-items-center justify-cintent-center gap-3 text-center ">

                    <div className="card-img">
                      <img src={EmptyQuizImg} width="200" height="200" className="img-fluid mx-auto" alt="Empty Quiz Icon" />
                    </div>
                    <h4 className="card-title fw-bold display-4">Create quiz</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate omnis nobis obcaecati quibusdam labore reprehenderit doloribus possimus nihil porro esse quam repudiandae provident dolorem quasi et, incidunt aliquam amet odio!</p>
                    <button className="btn btn-primary"
                      onClick={() => {
                        if (openModal) setOpenModal(false);
                        else setOpenModal("add");
                      }}
                    >
                      {existingQuiz.length > 0
                        ? openModal
                          ? "Close Quiz"
                          : "Add Quiz"
                        : "Create Quiz"}
                    </button>
                  </div>
                </Col>


              </>}
          </Row>

          {/* Popup for adding and updating quiz make this popup */}
          {openModal ? (
            <AddUpdateQuiz
              assessment={assessment}
              pageType={openModal}
              setOpenModal={setOpenModal}
              selectedQuiz={selectedQuiz}
            />
          ) : null}
        </Container>
      </div>


    </React.Fragment>
  );
};

export default QuizHome;
