import React, { useEffect, useState } from 'react'
import useAxiosAuth from '../../lib/useAxiosAuth';
import { Link, json, useNavigate } from 'react-router-dom';
import { Container, Row, Card, Col, CardBody } from 'react-bootstrap';
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [formData, setFormData] = useState({
    phone: '',
    otp: '',
    type: 'register'
  });

  useEffect(() => {
    document.body.className = "bg-pattern flex-center";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosAuth.post(`accounts/varify-otp/`, { ...formData });
      localStorage.setItem("token", res?.data?.token);
      if (res?.data?.token) {
        toast.success("Logged in successfully")
        navigate("/dashboard")
      }
    } catch (error) {
      console.log(error);
      console.log(error);
      if (error?.message === "Request failed with status code 400") {
        toast.error("Invalid OTP")
      }
    }


  }
  const handleOTP = async () => {
    const formValues = new FormData()
    formValues.append("phone", formData.phone)
    try {
      const res = await axiosAuth.post(`accounts/register/`, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      if (res.status === 200) toast.success("OTP Sent")
      else toast.success("Error in sending OTP")
    } catch (error) {
      toast.error(JSON.stringify(error))
      console.log(error);
    }
  }
  return (

    <>

      <div className="bg-overlay"></div>
      <div className="account-pages my-0 py-5 w-100">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} md={8} xl={4}>
              <Card className='mt-5'>
                <CardBody className="p-4">
                  <div className='card-top'>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={logodark}
                          alt=""
                          width="200"
                          height="42"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          width="200"
                          height="42"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    {/* <h4 className="font-size-18 text-muted mt-2 text-center">
                      Welcome Back !
                    </h4> */}
                    <p className="mb-5 mt-3 text-center">
                      Sign in to continue to Astro Laxmi.
                    </p>

                  </div>
                  <div className="card-bottom">
                    <form onSubmit={handleSubmit}>

                      <div className="form-group mb-3">
                        <label htmlFor="phone">Enter Mobile No. <sup className='text-danger'>*</sup></label>
                        <div className="input-group">
                          <input type="text" name="phone" className='form-control' placeholder='ex: 9876xxxxxx' value={formData.phone} onChange={handleChange} />
                          <div className="input-group-append">
                            <button onClick={handleOTP} className="btn btn-secondary" type="button" disabled={formData.phone.length < 10}>Get OTP</button>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="otp">Enter OTP <sup className='text-danger'>*</sup></label>
                        <input type="text" name="otp" className='form-control' placeholder='Enter 6 Digits OTP' value={formData.otp} onChange={handleChange} />
                      </div>

                      <div className="form-group mb-3">
                        <button type="submit" className="btn btn-primary w-100 btn-lg" disabled={formData.otp.length < 6}>Login</button>
                      </div>

                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">
                  © {new Date().getFullYear()} Astro Laxmi. Developed by <Link to="https://www.kodehash.com/" target="_blank">Kodehash Technologies</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Login