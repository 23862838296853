import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Calendar
import calendar from "./calendar/reducer";

// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import account from "./auth/register/reducer";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  // public
  Layout,
  calendar,
  forgetPassword,
  login,
  profile,
  account,
  
});

const rootModifier = (state, action) => {
  if (action.type === "SIGN_OUT") {
    state = undefined
    storage.removeItem("persist:root");
    localStorage.clear();
    window.location.reload()
    // return rootReducer(undefined, action);
  }
 
  return rootReducer(state, action);
};

export default rootModifier;
