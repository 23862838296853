import React from "react";
import { useEffect } from "react";
import { memo } from "react";
import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import useAxiosAuth from "../../lib/useAxiosAuth";
import { Form } from "react-router-dom";

const AddUpdateQuiz = ({
  assessment = "",
  pageType = "add",
  setOpenModal,
  selectedQuiz = {},
}) => {
  const axiosAuth = useAxiosAuth();
  const [formData, setFormData] = useState(
    Object.keys(selectedQuiz)?.length > 0 ? selectedQuiz : {}
  );

  function handleChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (pageType === "update") updateQuizApi();
    else addQuizApi();
  }

  // add module api
  async function addQuizApi() {
    if (Object.keys(formData)?.length > 0) {
      // for knowing which module
      formData.assessment = assessment;
      formData.answer = formData[formData?.correct_answer];

      try {
        const res = await axiosAuth.post("/operation/questions/", {
          ...formData,
        });
        console.log(res);
        if (res) setOpenModal(false);
      } catch (error) {
        console.log(error);
      }
    }
  }

  // update module api
  async function updateQuizApi() {
    if (Object.keys(formData)?.length > 7 && pageType === "update") {
      formData.answer = formData[formData?.correct_answer];

      try {
        const res = await axiosAuth.put(
          `/operation/questions/${formData?.id}/`,
          {
            ...formData,
          }
        );

        if (res) {
          console.log("res", res);
          setOpenModal(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <div className="quiz-modal">
        <h2 className="title mb-4">Quiz:</h2>

        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <div className="form-group mb-3" >
                <label htmlFor="question">Question</label>
                <input
                  type="text"
                  name="question"
                  className="form-control"
                  value={formData?.question || ""}
                  onChange={handleChange}
                  required
                  placeholder="Enter Question..."
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group mb-3">
                <label htmlFor="option1">Option 1</label>
                <input
                  type="text"
                  name="option_1"
                   className="form-control"
                  value={formData?.option_1 || ""}
                  onChange={handleChange}
                  required
                  placeholder="Enter Option"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group mb-3">
                <label htmlFor="option2">Option 2</label>
                <input
                  type="text"
                  name="option_2"
                  className="form-control"
                  value={formData?.option_2 || ""}
                  onChange={handleChange}
                  required
                  placeholder="Enter Option"
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="form-group mb-3">
                <label htmlFor="option3">Option 3</label>
                <input
                  type="text"
                  name="option_3"
                  className="form-control"
                  value={formData?.option_3 || ""}
                  onChange={handleChange}
                  required
                  placeholder="Enter Option"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group mb-3">
                <label htmlFor="option4">Option 4</label>
                <input
                  type="text"
                  className="form-control"
                  name="option_4"
                  value={formData?.option_4 || ""}
                  onChange={handleChange}
                  required
                  placeholder="Enter Option"
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group mb-3">
                <label htmlFor="optionsanswer">Select Answer:</label>
                <select
                  name="correct_answer"
                  className="form-control"
                  value={formData?.correct_answer || ""}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Please select
                  </option>
                  <option value="option_1">option_1</option>
                  <option value="option_2">option_2</option>
                  <option value="option_3">option_3</option>
                  <option value="option_4">option_4</option>
                </select>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="answer_detail"

                  className="form-control"
                  value={formData?.answer_detail || ""}
                  onChange={handleChange}
                  required
                  placeholder="Type Answers Details.."
                />
              </div>
            </Col>


            <Col md={12}>
              {pageType === "update" ? (
                <button className="btn btn-with-icon btn-primary icon-after" type="submit">
                  <span className="btn-icon">
                    <i className="iconsax" icon-name="tick-circle"></i>
                  </span>
                  <span className="btn-text">update quiz</span>
                </button>
              ) : (
                <button className="btn btn-with-icon btn-primary icon-after" type="submit">
                  <span className="btn-icon">
                    <i className="iconsax" icon-name="tick-circle"></i>
                  </span>
                  <span className="btn-text">add quiz</span>
                </button>
              )}
            </Col>

          </Row>
        </form>
      </div>
    </>
  );
};

export default memo(AddUpdateQuiz);
