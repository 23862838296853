import React from "react";
import { useState } from "react";
import { useEffect } from "react";
// import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import useAxiosAuth from "../../lib/useAxiosAuth";
import { Modal } from "react-bootstrap";

const Privacy = () => {
  const axiosAuth = useAxiosAuth();
  const [content, setContent] = useState("");
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState(null);
  const [updatePrivacy, setUpdatePrivacy] = useState(null);
  const [formData, setFormData] = useState({
    content_0: "",
    heading_1: "",
    content_1: "",
    heading_2: "",
    content_2: "",
    heading_3: "",
    content_3: "",
    heading_4: "",
    content_4: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get module api
  async function getPrivacy() {
    try {
      const res = await axiosAuth.get(`operation/privacyandpolicy/`);
      console.log(res.data.content_0, res.data.content_1);

      // setContent(res.data.content_0 + res.data.content_1);
      document.getElementById("privacy-content").innerHTML =
        res.data.content_0 + res.data.content_1;
      // setUpdatePrivacy(res.data);
      setContent(res.data);
      console.log(res.data);

      //  console.log(theObj);
      // setContent(data);
    } catch (error) {
      console.log(error);
    }
  }

  const handlechange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const res = await axiosAuth.put(`operation/privacyandpolicy/${editId}/`, {
        ...formData,
      });
      handleClose();
      getPrivacy();
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log("getting quiz home");
    getPrivacy();
  }, []);
  return (
    <div className="page-content">
      <Container fluid={true}>
        <Row>
          <Col md={12}></Col>
        </Row>

        <div>
          <p id="privacy-content" style={{ textAlign: "justify" }}></p>
          <div className="page-header d-flex align-itens-center justify-content-between">
            <h2>{content?.heading_1}</h2>
            <button
              className="btn btn-grey btn-with-icon icon-after btn-sm"
              onClick={() => {
                // setUpdateName(item.name)
                handleShow();
                // updateModuleApi(item.id);
              }}
            >
              <span className="btn-icon">
                <i className="iconsax" icon-name="edit-2"></i>
              </span>
              <span className="btn-text">edit</span>
            </button>
          </div>

          <p>{content?.content_1}</p>
          <heading_2>{content?.heading_2}</heading_2>
          <p>{content?.content_2}</p>
          <h2>{content?.heading_3}</h2>
          <p>{content?.content_3}</p>
          <h2>{content?.heading_4}</h2>
          <p>{content?.content_4}</p>
        </div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Privacy and Policies </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-wrapper">
                <div className="form-group mb-4">
                  <label>Content 0</label>
                  <textarea
                    name="content_0"
                    className="form-control"
                    value={
                      formData.content_0
                        ? formData.content_0
                        : content.content_0
                    }
                    onChange={handlechange}
                  />
                </div>

                <div className="form-group mb-4">
                  <label>Heading 1</label>
                  <input
                    name="heading_1"
                    className="form-control"
                    value={
                      formData.heading_1
                        ? formData.heading_1
                        : content.heading_1
                    }
                    onChange={handlechange}
                  />
                </div>

                <div className="form-group mb-4">
                  <label>Content 1</label>
                  <textarea
                    type="text"
                    name="content_1"
                    value={
                      formData.content_1
                        ? formData.content_1
                        : content.content_1
                    }
                    onChange={handlechange}
                    rows={5}
                    style={{ width: "100%" }}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-4">
                  <label>Heading 2</label>
                  <input
                    name="heading_2"
                    className="form-control"
                    value={
                      formData.heading_2
                        ? formData.heading_2
                        : content.heading_2
                    }
                    onChange={handlechange}
                  />
                </div>
                <div className="form-group mb-4">
                  <label>Content 2</label>
                  <textarea
                    type="text"
                    name="content_2"
                    className="form-control"
                    value={
                      formData.content_2
                        ? formData.content_2
                        : content.content_2
                    }
                    onChange={handlechange}
                    rows={5}
                    style={{ width: "100%" }}
                  />
                </div>

                {content.content_3 && (
                  <>
                    <div className="form-group mb-4">
                      <label>Heading 3</label>
                      <input
                        name="heading_3"
                        value={
                          formData.heading_3
                            ? formData.heading_3
                            : content.heading_3
                        }
                        onChange={handlechange}
                      />
                    </div>

                    <div className="form-group mb-4">
                      <label>Content 3</label>
                      <textarea
                        type="text"
                        name="content_3"
                        className="form-control"
                        value={
                          formData.content_3
                            ? formData.content_3
                            : content.content_3
                        }
                        onChange={handlechange}
                        rows={5}
                      />
                    </div>

                    <div className="form-group mb-4">
                      <label>Heading 4</label>
                      <input
                        name="heading_4"
                        className="form-control"
                        value={
                          formData.heading_4
                            ? formData.heading_4
                            : content.heading_4
                        }
                        onChange={handlechange}
                      />
                    </div>

                    <div className="form-group mb-4">
                      <label>Content 4</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="content_4"
                        value={
                          formData.content_4
                            ? formData.content_4
                            : content.content_4
                        }
                        onChange={handlechange}
                        rows={5}
                      />
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  setEditId(content.id);
                }}
              >
                Update Now
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </Container>
    </div>
  );
};

export default Privacy;
