import React from "react";
import UsePanel from "./UserPanel";
import OverView from "./OverView";
import { Row, Container } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import axios from "../../lib/axios";
// import useAxiosAuth from "../../lib/useAxiosAuth";

const Dashboard = () => {
  document.title = "Astro Laxmi || Dashboard";
  // const axiosAuth = useAxiosAuth()

  // useEffect(() => {
  //   const login = async () => {
  //     console.log("hello")
  //     try {
  //       let formdata = new FormData();
  //       formdata.append("phone", "9990020799");
  //       const res = await axiosAuth.post("accounts/login/", formdata)
  //       console.log(res)
  //     } catch (error) {
  //       console.log(error)
  //     }

  //   }
  //   login()

  // }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="Upzet" breadcrumbItem="Dashboard" /> */}
          {/* User Panel Charts */}
          <UsePanel />

          <Row>
            {/* Overview Chart */}
            <OverView />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
